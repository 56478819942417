<template>
    <div id='WebSocket' v-if="devStatus">
      <br>
      <button v-on:click='showConsole = !showConsole'>API</button>
      <transition  name='ws-slide-fade'  mode='out-in' appear>
        <div v-if='showConsole'>
          <h1>API</h1>
  
          <br>
          <label for='command'>Message</label>
          <br>
          <input type='text' id='command2' v-model='userData.command'>
  
          <label for='data'>Data</label>
          <br>
          <br>
          <textarea id='datasend' rows='4' v-model='userData.data'></textarea>
          <br>
          <button @click='do_command()'>Send Command</button>
  
          <br>
          <textarea id='datarecv' rows='40' v-model='dataRecv'></textarea>
  
          <div v-if='jwt'>
            <p id='jwt_token'>{{jwt}}</p>
          </div>
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  // import { Auth } from 'aws-amplify'
  // import Vue from 'vue';
  // import { components } from 'aws-amplify-vue';
  // import {bus} from '@/main';
  
  import store from '@/store/index';
  
  import awsInfo from '@/utils/websocket';
  
  export default {
    name: 'WebSocket',
    props: {
      token: {
        type: Object,
      },
    },
    data() {
      return {
        ws: awsInfo.aws,
        userData: { command: 'GetDeviceList', data: '{}' },
        dataRecv: '',
        showConsole: false,
      };
    },
    filters: {
      pretty(value) {
        return JSON.stringify(JSON.parse(value), null, 2);
      },
    },
    methods: {
      receive_cb(o) {
        this.$emit('receivedMessageEvent', o);
        this.dataRecv = `${this.dataRecv}\nRECV: ${JSON.stringify(o)}\n`;
      },
      sent_cb(o) {
        this.dataRecv = `${this.dataRecv}\nSEND: ${JSON.stringify(o)}\n`;
      },
      do_command() {
        awsInfo.aws.send_command(
          this.userData.command,
          this.userData.data,
        );
      },
      jwt_cb() {
        return new Promise((resolve) => resolve(store.getters.getToken));
      },
      token_update_cb(newToken) {
        console.log('Updating Token');
        store.commit('setToken', newToken);
      },
    },
    computed: {
      devStatus() {
        if (process.env.NODE_ENV === 'development') {
          return true;
        } return false;
      },
      jwt() {
        return store.getters.getToken;
      },
    },
  
    created() {
      console.log('WEBSOCKET: Created...');
      awsInfo.connect(this.receive_cb, this.sent_cb, this.jwt_cb, this.token_update_cb);
    },
  
    mounted() {
      console.log('WEBSOCKET: Mounted...');
    },
  
    beforeUnmount() {
      console.log('WEBSOCKET: Destroy...');
      awsInfo.disconnect();
    },
  };
  </script>
  
  <style>
  #jwt_token {
  
      border-radius: 20px;
  }
  
  textarea, select {
    background-color: transparent;
    width: 90%;
  }
  
  #command2 {
  
    width: 90%;
  }
  
  .ws-slide-fade-enter-active {
      transition: all .4s ease;
  }
  
  .ws-slide-fade-leave-active {
      transition: all .4s ease;
  }
  
  .ws-slide-fade-enter {
      transform: translateY(10px);
      opacity: 0;
  }
  
  .ws-slide-fade-leave-to {
      transform: translateY(100px);
      opacity: 0;
  }
  
  </style>