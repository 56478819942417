// https://gist.github.com/WebReflection/6076a40777b65c397b2b9b97247520f0

function toDatetimeLocal (date) {
    var ten = function (i) {
      return (i < 10 ? '0' : '') + i;
    },
    YYYY = date.getFullYear(),
    MM = ten(date.getMonth() + 1),
    DD = ten(date.getDate()),
    HH = ten(date.getHours()),
    II = ten(date.getMinutes()),
    SS = ten(date.getSeconds())
  ;
  return YYYY + '-' + MM + '-' + DD + 'T' +
          HH + ':' + II + ':' + SS;
}

module.exports = { toDatetimeLocal }