import { Auth } from 'aws-amplify';

export default {
    state: {
        token: '',
        loggedIn: false,
        loggingIn: false,
        loginPromise: null,
        fetchedUser: false
    },
    mutations: {
        setLoggingIn (state, value) {
            
            if (value !== false && value !== true) {
                throw new Error('[Auth State] Store variable \'loggingIn\' can only accept a boolean type. ')
            }

            state.logginIn = value
        },
        setToken (state, value) {
            state.token = value
        },
        setLoggedIn (state, value) {
            state.loggedIn = value
        },
        setFetchedUser (state, value) {
            state.fetchedUser = value
        }
    },
    actions: {
        async login (context, { username, password }) {

            if (context.logginIn === true) {
                return context.loginPromise
            } else {
                context.loginPromise = new Promise((resolve, reject) => {

                    Auth.signIn(username, password).then(user => {

                        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                            resolve(user)
                            return
                        }

                        const jwt = user
                            .getSignInUserSession()
                            .getIdToken()
                            .getJwtToken();

                        context.commit('setToken', jwt);
                        context.commit('setLoggedIn', true);

                        resolve()

                    }).catch(e => {
                        reject(e)
                        return
                    })
                })
                
                return context.loginPromise
            }
        },
        async fetchUser (store) {
            return new Promise( (resolve, reject) => {
      
              Auth.currentSession().then(session => {
                if (session !== null) {
                  let jwt = session.getIdToken().getJwtToken();
      
                  store.commit('setToken', jwt)
                  store.commit('setLoggedIn', true)
                  store.commit('setFetchedUser', true)
                  resolve(jwt)
                } else {
                  store.commit('setLoggedIn', false)
                  store.commit('setToken', '')
                  store.commit('setFetchedUser', false)
                  resolve(null)
                }
              }).catch(err => reject(err))
            })
          }
    },
    getters: {
        
        loggedIn (state) {
          return state.loggedIn
        },

        fetchedUser (state) {
          return state.fetchedUser
        },
        getToken (state) {
            return state.token
        }
    }
}