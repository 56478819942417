/* eslint-disable */
//import getUser from '@/utils/getUser.js'
import { nanoid } from 'nanoid'
import { Auth } from 'aws-amplify';

//const ws_host = "wss://ou6291oqbc.execute-api.eu-west-2.amazonaws.com";
const ws_host = "wss://qrf4x4bn6f.execute-api.eu-west-2.amazonaws.com/v2";

var aws_info = {
	outgoing: [],
	rectangles: null,
	errors: 0,
	aws: null,
	devices: null,
	found_cameras: null,
	onvif: null,
	token_cb: null,
	recv_cb: null,
	sent_cb: null,
	token_update_cb: null,
	selectedDeviceId: "tempest-dev-5",
	liveview_transactionId: null,
	liveview_camera: null,
	flush_queue: function() {
		while( aws_info.outgoing.length ) {
			aws_info.aws.write( aws_info.outgoing.shift() );
		}
	},
	getMyInfo: function () {
		aws_info.aws.send_command("GetMyInfo", '{}')
	},
	list: function() {
		aws_info.aws.send_command("GetDeviceList", "{}");
	},
	addDevice: function (id, name, type, location) {
		var data = {
			device: {
				id,
				name,
				type
			},
		}

		if(location != null || location != '') data.device.location = location

		console.log(data)
		aws_info.aws.send_object("AddDevice", data)
	},
	getDeviceInfo: function(deviceId, orgId) {
		aws_info.aws.send_object('GetDeviceInfo', {
			deviceId
		})
	},
	deleteDevice: function (deviceId, orgId) {

		var data = {
			deviceId,
		}

		if (orgId != null) data.orgId = orgId

		aws_info.aws.send_object("DeleteDevice", data)
	},
  enableCamera: function( cameraId ) {
    aws_info.aws.send_object('SetCameraEnabled', {
      cameraId
    })

  },
  disableCamera: function( cameraId ) {
    aws_info.aws.send_object('SetCameraDisabled', {
      cameraId
    })

  },
	listCameras: function( device ) {
//		aws_info.selected_deviceId = device;
		aws_info.aws.send_object("DeviceOnvifDiscover", {
			deviceId: device,
			timeout: 20,
		});
	},
	findCameras: function( device ) {
		aws_info.aws.send_object("DeviceOnvifDiscover", {
			deviceId: device,
			timeout: 20,
		});
	},
	getOnvifDetail: function( device, mac, user, pass ) {
		aws_info.aws.send_object("GetOnvifConfiguration", {
			deviceId: device,
			onvifRequest: {
				mac: mac,
				user: user,
				pass: pass,
			},
		});
	},
	addCamera: function(device, name, type, url, mac, username, password, video, audio) {
		var o = {
			deviceId: device,
			camera: {
				name: name,
				type: type,
				url: url,
				mac: mac,
				username: username,
				password: password,
				audio: audio,
				video: video,
			},
		};
		aws_info.aws.send_object("AddCamera", o);
	},
	updateCamera: function (deviceId, cameraId, data) {
		data['id'] = cameraId
		var requestData = {
			deviceId,
			camera: data
		}
		aws_info.aws.send_object("UpdateCamera", requestData)
	},
	getCameraInfo: function(cameraId) {
		var data = {
			cameraId
		}
		aws_info.aws.send_object("GetCamInfo", data)
	},
	deleteCamera: function(device, camera) {
		var o = {deviceId: device, cameraId: camera};
		aws_info.aws.send_object("DeleteCamera", o);
	},
	liveView: function( camera ) {
		var webRtcData = JSON.parse("{}");
		var msgData = {
			camId: camera,
			webRtcData: webRtcData,
		};
		// By sending this liveview command, the txid and suid are latched.
		aws_info.aws.send_object("liveview", msgData );
	},
	connect: function(receive_cb, sent_cb, token_cb, token_update_cb) {
		if (aws_info.aws == null) {
			aws_info.setRecv(receive_cb);
			aws_info.setSent(sent_cb);
			aws_info.setTokenCb( token_cb );
			aws_info.setTokenUpdateCB(token_update_cb);
			aws_info.do_connect();
		}
	},
	do_connect: function() {
		var t = aws_info.token_cb().then( (token) => {
			if ( token == null ) return;
			var url = ws_host + "?token=" + token;
			console.yellow("connecting to " + url);
			aws_info.aws = new AwsSocket(url, "");
		})
	},
	reconnect: async function() {
	   console.log( "Reconnecting...");
		if (aws_info.aws == null) {
			aws_info.do_connect();
		}
	},
	disconnect: function() {
		if ( aws_info.aws )
			aws_info.aws.close();
		aws_info.aws = null;
	},
	setRecv: function(cb) {
		aws_info.recv_cb = cb;
	},
	setSent: function(cb) {
		aws_info.sent_cb = cb;
	},
	setTokenCb: function(cb) {
		aws_info.token_cb = cb;
	},
	setTokenUpdateCB: function(cb) {
		aws_info.token_update_cb = cb;
	}
};


var AwsSocket = function(server, proto) {
	this.server = server;
	this.proto = proto;
	this.sent = 0;
	this.ws = new WebSocket(server);

	this.send_object = function(message, obj) {
		var msg = {action: message, data: obj};
		msg.data.transactionId = nanoid();

		// If this is liveview command then stash the id's for later use.
		if ( message == "liveview" )
		{
			console.log('[Debug]', msg.data.transactionId)
			aws_info.liveview_transactionId = msg.data.transactionId;
			aws_info.liveview_camera = msg.data.camId;
		}

		var m = JSON.stringify(msg);
		if (message == "liveview") {
			console.log('[Debug] Liveview Message: ', m)
		}
		this.push(m);
	};

	this.send_command = function(message, obj) {
		this.send_object( message, JSON.parse( obj ) );
	};

	this.send_log = function(str) {
		var msg = {action: "log", data:str};
		var m = JSON.stringify(msg);
		this.push( m );
	};

	this.send_dcv2 = function(camId, transactionId, webRtcData) {
		var msgData = {
			camId,
			transactionId,
			webRtcData,
		};

		var msg = { action: 'liveview', data: msgData };
		var m = JSON.stringify(msg);
		this.push(m);
	};

	// send webrtc command
	// This is called send because janus.js calls that function name in the original class.
	this.send = function(str) {
		var suid = "suid";
		var session = "session";
		var webRtcData = JSON.parse(str);

// outgoing live view message will need a suid.
// Associate the suid with the janus session ids
// When sending,
// if there is a tempest session id associated with a janus session then retrieve it and the camera id and include them in the message
// If there was no tempest session id then send the camera id in the message, and the response will include a tempest session id which can be saved.


		var msgData = {
			camId: aws_info.liveview_camera,
			transactionId: aws_info.liveview_transactionId,
			webRtcData: webRtcData,
		};
		var msg = {action: "liveview", data: msgData};
		var m = JSON.stringify(msg);
		this.push( m );
	};

	this.write = function( str ) {
		try {
			console.log("SEND: " + str);
			this.ws.send(str);
			aws_info.sent_cb(str);
			this.sent++;
		} catch ( e ) {
			console.red( "SEND ERROR " );
			console.log( e );
		}
	}

	// Take a command and queue it until the websocket is able to send it.
	this.push = function( str ) {
		if ( this.ws.readyState == WebSocket.OPEN ) {
			// send it now
			this.write( str );
		}
		else {
			// push to queue
			console.yellow( "PUSH: " + str );
			aws_info.outgoing.push( str );
		}
	}

	this.close = function() {
		console.red("AWS CLOSING");
		this.ws.close();
		aws_info.aws = null;
	};

	this.onopen = function() {
		console.green("AWS ON OPEN");
		aws_info.flush_queue();
		aws_info.keepalive = window.setInterval( function() {
			aws_info.aws.write(" ");
		}, 160000 );
		aws_info.list();
		aws_info.getMyInfo()
	};

	this.onerror = function(e) {
		console.red("AWS ON ERROR");
		aws_info.errors += 1;
		console.log( "error count = ", aws_info.errors, " ", e);
	};

	this.onclose = function (e) {
    console.red("AWS ON CLOSE - " + e.code + " " + e.reason);

    if (aws_info.keepalive) {
      window.clearInterval(aws_info.keepalive);
    }

    Auth.signOut()
      .then(() => {
        console.log("User signed out successfully");
        window.location.href = "/";
      })
      .catch((err) => {
        console.error("Error signing out:", err);
        window.location.href = "/";
      });
  };

	this.addEventListener = function(eventName, wsHandler) {
		this.ws.addEventListener(eventName, wsHandler);
	};

	this.removeEventListener = function(eventName, wsHandler) {
		this.ws.removeEventListener(eventName, wsHandler);
	};

	this.onmessage = function(m) {
		var o;
		try {
			o = JSON.parse(m.data);

			if (o.status == undefined || o.status == 200) console.log("RECV: " + JSON.stringify(o.data, null, 3));
			else if ( o.status == 202 ) {}
			else console.error("RECV: " + m.data);

			if (o == undefined) return;

			// janus will handle the sendwebrtc message responses
			if (o.response == "sendwebrtc") return;

			if (( o.response == "GetDeviceList" || o.response == "getcameras" ) && o.data != undefined) {
				aws_info.devices = o.data;
			}
			else if (o.response == "DeviceOnvifDiscover" && o.data != undefined) {
				aws_info.found_cameras = o.data;
			}
			else if (o.response == "GetOnvifConfiguration" && o.data != undefined) {
				aws_info.onvif = o.data;
			}

			// Notify components via event bus
			aws_info.recv_cb(o);
		} catch (e) {
			console.log("RECV TEXT: " + m.data);
		}
	};

	this.ws.addEventListener("open", this.onopen);
	this.ws.addEventListener("close", this.onclose);
	this.ws.addEventListener("error", this.onerror);
	this.ws.addEventListener("message", this.onmessage);
};

export default aws_info
