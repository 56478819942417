<template>
    <div class="content">
        <div class="spinner">
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="8" cy="8" r="7" stroke-width="2" />
            </svg>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style scoped>
.spinner {
    height: 42px;
    width: 42px;
}

.spinner svg {
    width: 100%;
    height: 100%;
    overflow: visible;
    animation: rotation 1455ms infinite linear;
}

.spinner circle {
    stroke: currentColor;
    stroke-dasharray: 44px;
    stroke-dashoffset: 11px;
    transform-origin: center;
    transform: rotatey(180deg) rotate(90deg);
    animation: spinner 3850ms infinite ease;
}

@keyframes spinner {
    0% {
        stroke-dashoffset: 33px;
        transform: rotatey(0deg) rotate(0deg);
    }
    24.9999% {
        stroke-dashoffset: 11px;
        transform: rotatey(0deg) rotate(0deg);
    }
    25% {
        stroke-dashoffset: 11px;
        transform: rotatey(180deg) rotate(270deg);
    }
    49.9999% {
        stroke-dashoffset: 33px;
        transform: rotatey(180deg) rotate(270deg);
    }
    50% {
        stroke-dashoffset: 33px;
        transform: rotatey(0deg) rotate(180deg);
    }
    74.9999% {
        stroke-dashoffset: 11px;
        transform: rotatey(0deg) rotate(180deg);
    }
    75% {
        stroke-dashoffset: 11px;
        transform: rotatey(180deg) rotate(90deg);
    }
    100% {
        stroke-dashoffset: 33px;
        transform: rotatey(180deg) rotate(90deg);
    }
}

@keyframes rotation {
    100% {
        transform: rotate(360deg);
    }
}

.content {
    height: 100%;
    display: flex;
    z-index: 20;
    justify-content: center;
    align-items: center;}
</style>
