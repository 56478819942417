<template>
  <div class="container">
    <div class="wrapper">
      <div class="grid" @click="selectEvent($event)">
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100">
          <!-- Left Arrow (Pan) -->
          <polygon points="20,50 30,40 30,45 50,45 50,55 30,55 30,60" fill="black" stroke="white" stroke-width="1" opacity="0.5" />

          <!-- Up Arrow (Tilt) -->

          <!-- Right Arrow (Pan) -->
          <polygon points="80,50 70,40 70,45 50,45 50,55 70,55 70,60" fill="black" stroke="white" stroke-width="1" opacity="0.5" />

          <!-- Down Arrow (Tilt) -->
          <!-- Bottom Arrowhead -->
          <polygon points="50,95 45,90 55,90"  fill="black" stroke="white"  stroke-width="1" opacity="0.5" />

          <!-- Arrow Shaft -->
          <rect x="45" y="13" width="10" height="74"  fill="black" stroke="white" stroke-width="1" opacity="0.5"  />

          <!-- Top Arrowhead -->
          <polygon points="55,10 45,10, 50,5"  fill="black" stroke="white" stroke-width="1" opacity="0.5"  />
          <!-- "Pan" and "Tilt" labels -->
            <text x="6" y="35" fill="white" font-family="Arial" font-size="14" stroke-width="1" opacity="0.5">Pan</text>
            <text x="60" y="95" fill="white" font-family="Arial" font-size="14" stroke-width="1" opacity="0.5">Tilt</text>
        </svg>
      </div>

      <div class="zoom" @click="zoomEvent($event)">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="100">
          <!-- Up Arrow -->
          <polygon points="10,10 15,5 20,10" fill="white" stroke-width="1" opacity="0.5"/>

          <!-- "zoom" text rotated vertically -->
          <text x="15" y="50" fill="white" font-family="Arial" font-size="14" text-anchor="middle" stroke-width="1" opacity="0.5">
            <tspan x="15" dy="-1em">Z</tspan>
            <tspan x="15" dy="1em">O</tspan>
            <tspan x="15" dy="1em">O</tspan>
            <tspan x="15" dy="1em">M</tspan>
          </text>

          <!-- Down Arrow -->
          <polygon points="10,90 15,95 20,90" fill="white" stroke-width="1" opacity="0.5"/>
        </svg>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    zoomEvent(event) {
      const zoom = -(event.offsetY - 100 / 2) / 50;
      console.log(zoom);
      this.sendMessage(0, 0, zoom);
    },
    selectEvent(event) {
      const pan = (event.offsetX - 100 / 2) / 50;
      const tilt = -(event.offsetY - 100 / 2) / 50;
      console.log(pan, tilt);
      this.sendMessage(pan, tilt, 0);
    },
    sendMessage(pan, tilt, zoom) {
      const message = {
        topic: 'cmd/devices/hilary-dev/data-channel-relay',
        destination: 'onvif-ptz',
        action: {
          command: 'ptz',
          data: {
            pan, tilt, zoom,
          },
        },
      };
      console.log(message);
      this.$parent.sendData(message);
    },
  },

  created() {
  },

  unmounted() {
  },
};
</script>

<style scoped>
.container {
  z-index: 20;
}

.wrapper {
  opacity: 0.2;
  transition: opacity  0.5s ease-in-out;
    display: flex;
    justify-content: space-between; 
    scale: 0.8;
}

.wrapper:hover {
  opacity: 1.0;
}

.grid {
  display: inline-block;
  border: 0px solid white;
  cursor: crosshair;
}

.zoom {
  display: inline-block;
  border: 0px solid white;
  cursor: crosshair;
}
</style>
