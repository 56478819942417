import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

import store from '../store'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/auth/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/SignInView.vue')
  },
  {
    path: '/auth/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/auth/SignOutView.vue')
  },
  {
    path: '/cameras/manage',
    name: 'manage-cameras',
    component: () => import(/* webpackChunkName: "manage-cameras" */ '../views/cameras/ManageCameras.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


router.beforeEach(async (to, from, next) => {

  if (store.getters.fetchedUser == false) {
  

    try {
      let token = await store.dispatch('fetchUser')

      if (token == null || token == undefined || token === '') {
        to.fullPath.name === 'login' ? next() : next({ name: 'login'})
        return
      }

    } catch (e) {
      if (e !== 'No current user') {
        console.error(e)
      }
    }

  }
  
  if (to.name === 'login') {
    store.getters.loggedIn ? next({ name: from.name !== 'login' ? from.name : 'home' }) : next()
    return
  }

  let loggedIn = store.getters.loggedIn
  loggedIn ? next() : next({ name: 'login' })

})

export default router
