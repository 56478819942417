<template>
    <div class="wrapper">
        <transition-group name="slide-fade">
            <notification-message
                v-for="notif in notifications"
                :key="notif.id"
                :notif="notif"
            />
        </transition-group>
    </div>
</template>

<script>
import notificationMessage from './NotificatiMessage.vue'

export default {
  // eslint-disable-next-line
  name: 'Notifications',
  components: { notificationMessage },
  computed: {
    notifications() {
      return this.$store.state.notification.notifications
    },
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
    transition: all 0.3s ease;
}
.slide-fade-leave-active {
    transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}
.wrapper {
    align-items: center;
    position: absolute;
    top: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    z-index: 9999999;
}
.card {
    width: 100%;
    height: 80px;
    margin-bottom: 12px;
    width: min(calc(100vw - 60px), 320px);
    border-radius: 11px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.red {
    background: var(--standardRed);
}
.green {
    background: var(--standardGreen);
    color: white;
}
</style>