<template>
  <notifications />
  <router-view/>
  <WebSocket ref="websocket" :token="{jwt}" @receivedMessageEvent="receivedMessageEvent" v-if="jwt !== ''"/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Outfit', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
}
body {
  min-width: 100vw;
  min-height: 100vh;
}

#app {
  height: 100%;
  width: 100%;
  position: absolute;
}

:root {
    --popper-theme-background-color: white;
    --popper-theme-background-color-hover: white;
    --popper-theme-text-color: black;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 32px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
    --softPurple: #6F6EC9;
    --overlayBlue: #2C2E42;
    --orange:#E99E87;
    --textWhite: #EAEBEC;
    --bg: #1F1C2A;
    --boxBlueGray: #262736;
    --standardRed: #EC4C4C;
    --standardGreen: #2fd772;
  }
</style>

<script>
import WebSocket from '@/components/WebSocket.vue';
import Notifications from '@/components/Notifications.vue';
import bus from '@/utils/EventBus';
export default {
  name: 'App',
  components: {
    WebSocket,
    Notifications
  },
  computed: {
    jwt () {
      return this.$store.getters.getToken
    }
  },
  methods: {
    receivedMessageEvent(message) {
      bus.$emit('receivedMessageEvent', message);
    },
    setupConsoleColours() {
      console.red = (str) => { console.log(`%c${str}`, 'color: black; background-color: red;'); };
      console.yellow = (str) => { console.log(`%c${str}`, 'color: black; background-color: yellow;'); };
      console.green = (str) => { console.log(`%c${str}`, 'color: black; background-color: green;'); };
      console.blue = (str) => { console.log(`%c${str}`, 'color: blue;'); };
      console.cyan = (str) => { console.log(`%c${str}`, 'color: cyan;'); };
    },
  },
  mounted () {
    this.setupConsoleColours()
  }
}
</script>
