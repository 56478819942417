import awsInfo from "@/utils/websocket";
import { nanoid } from "nanoid";
import bus from "@/utils/EventBus";
import store from "@/store/index";

let get_resolved = [];

class DCv2 {
  static randomId() {
    return Math.floor(Math.random() * 1000000000);
  }

  // Create a new DCv2 instance given a camera ID.
  // You can create many instances using the same camera ID and they are
  // each able to do different things / file transfer at the same time.
  constructor(camId) {
    this.camId = camId;
    this.transactionId = nanoid();
    this.offerId = DCv2.randomId();
    this.candidate_list = [];
    this.description_set = false;
    this.resolved = null;
    this.rejected = null;
    this.chunk = null;
    this.buffer = null;
    this.globalDc = null;
    this.globalPc = null;
    this.files = [];
    this.connected = false;
    bus.$on("receivedMessageEvent", this.receiveMessage.bind(this));
      

     /* 
    setInterval( async () => {
      const pc = this.globalPc;
      // const stats = await pc.getStats();
      const senders = pc.getSenders();
      if (!senders || senders.length < 1) return;
      const stats = await pc.getStats(senders[0].track);


      // Iterate over each RTCStats object
      stats.forEach((report) => {
        if (
          report.type === "candidate-pair" &&
          report.state === "succeeded" &&
          report.selected
        ) {
          // Now, report.localCandidateId and report.remoteCandidateId refer to stats objects of type "local-candidate" and "remote-candidate"
          let localCandidate = stats.get(report.localCandidateId);
          let remoteCandidate = stats.get(report.remoteCandidateId);

          console.log(
            `===== Local candidate ${localCandidate.ip}:${localCandidate.port}`
          );
          console.log(
            `===== Remote candidate ${remoteCandidate.ip}:${remoteCandidate.port}`
          );

          // Determine if it's going through a TURN server
          if (
            localCandidate.candidateType === "relay" ||
            remoteCandidate.candidateType === "relay"
          ) {
            console.log("===== Connection is going through a TURN server.");
          } else {
            console.log("===== Connection is not going through a TURN server.");
          }
        }
      });
    }, 2000);
    */

  }

  get_device_name(store) {
    // given camId
    console.log( "STORE",store)
    return store.getters.deviceFind(this.camId)?.name;
  }

  jwt() {
    return store.getters.getToken;
  }
  getCamId = () => {
    return this.camId;
  };

  config = {
    iceServers: [
      { urls: "stun:megakam.com:3478" },
      {
        urls: "turn:megakam.com:3478?transport=udp",
        username: "myuser",
        credential: "mypass",
      },
    ],
  };

  git_hash() {
    return process.env.VUE_APP_GIT_HASH;
  }

  git_time() {
    return process.env.VUE_APP_GIT_TIME.substring(0, 19);
  }

  isOpen() {
    return this.globalPc ? 1 : 0;
  }

  isConnected() {
    return this.connected;
  }
  
async  getConnectionType() {
  if (this.globalPc == null) return;

// Assume pc connection is established, and data channels/senders are added
  }

  getStatus() {
    let s = "Not Connected";
    if (this.isOpen() && !this.isConnected()) s = "Connecting";
    else if ( this.isConnected() ) s = "Connected"
    return s;
  }

  modifySdp(desc, maxSize) {
    const sdp = desc.sdp.replace(
      /a=max-message-size:([0-9]+)/,
      `a=max-message-size:${maxSize}`
    );
    return new RTCSessionDescription({
      type: desc.type,
      sdp,
    });
  }

  // Offer a peer connection to the edge device.
  // (Not the usual mode of operation.)
  offerPeerConnection(id) {
    // Create PeerConnection
    const pc = this.createPeerConnection(id);

    // Create DataChannel
    const label = "DCv2";

    const options = {
      ordered: true,
      reliable: true,
      protocol: "dcv2",
    };

    const dc = pc.createDataChannel(label, options);
    this.setupDataChannel(dc, id);

    // Send offer
    this.sendLocalDescription(id, pc, "offer");

    return dc;
  }

  // Create and setup a PeerConnection
  createPeerConnection(id) {
    const pc = new RTCPeerConnection(this.config);
    pc.onicecandidate = (e) => {
      if (e.candidate && e.candidate.candidate) {
        // Send candidate
        this.sendLocalCandidate(id, e.candidate);
      }
    };
    pc.onconnectionstatechange = () => {
      console.log(`[DC-${id}] Connection: ${pc.connectionState}`);
      if (
        pc.connectionState === "failed" ||
        pc.connectionState === "disconnected"
      ) {
        this.disconnect();
      }
    };
    pc.ondatachannel = (e) => {
      const dc = e.channel;
      console.log(
        `[DC-${id}] DataChannel opened, label ${dc.label} protocol ${dc.protocol}`
      );

      switch (dc.protocol) {
        case "DCv2-terminal":
          this.setupDataChannel_pty(dc, id);
          break;
        default:
          this.setupDataChannel(dc, id);
      }

      // Delay because Safari on ipad isn't ready to send messages immediately.
      window.setTimeout(() => {
        this.connected = true;
        // Now the DC is open the promise can be resolved.
        this.resolved();
      }, 50);
    };

    // When connection is established
    // eslint-disable-next-line
    pc.oniceconnectionstatechange = async (event) => {
      if (
        pc.iceConnectionState === "completed" ||
        pc.iceConnectionState === "connected"
      ) {
        this.connected = true;
      }
      else
      console.log("===== IceConState", event, pc);
    };

    this.globalPc = pc;
    window.pc = pc;
    return pc;
  }

  sendLocalDescription(id, pc, msgtype) {
    (msgtype === "offer" ? pc.createOffer() : pc.createAnswer())
      .then((desc) => this.modifySdp(desc, 262144 )) // max-message-size
      .then((desc) => pc.setLocalDescription(desc))
      .then(() => {
        const { sdp, type } = pc.localDescription;

        awsInfo.aws.send_dcv2(this.camId, this.transactionId, {
          dcv2: type,
          id,
          description: sdp,
        });
      });
  }

  sendLocalCandidate(id, cand) {
    const { candidate, sdpMid } = cand;

    awsInfo.aws.send_dcv2(this.camId, this.transactionId, {
      dcv2: "candidate",
      id,
      candidate,
      mid: sdpMid,
    });
  }

  // Setup a DataChannel
  setupDataChannel_pty(dc, id) {
    dc.onopen = () => {
      console.log(`[DC-${id}] DataChannel PTY open`);
        this.connected = true;
      bus.$emit( "dcv2-pty-status")
    };
    dc.onclose = () => {
      console.log(`[DC-${id}] DataChannel PTY closed`);
      this.disconnect();
      bus.$emit( "dcv2-pty-status")
    };
//    dc.onmessage = (msg) => {
 //     
  //  };

    this.globalDc = dc;
    window.dc = dc;
      bus.$emit( "dcv2-pty-status")
    return dc;
  }

  // Setup a DataChannel for FTP
  setupDataChannel(dc, id) {
    dc.onopen = () => {
      console.log(`[DC-${id}] DataChannel open`);
    };
    dc.onclose = () => {
      console.log(`[DC-${id}] DataChannel closed`);
      this.disconnect();
    };
    dc.onmessage = (e) => {
      // If the message contains a string then process it as a json command
      if (typeof e.data === "string") {
        try {
          const msg = JSON.parse(e.data);
          console.log(`[DC-${id}] DC-RECV:`, JSON.stringify(msg));

          // Handle responses
          if (msg.response === "delete") {
            if (get_resolved[msg["request-id"]])
              get_resolved[msg["request-id"]](msg);
          } else if (
            msg.status === 200 &&
            msg.response === "list" &&
            msg.list
          ) {
            this.files = msg;
            if (get_resolved[msg["request-id"]])
              get_resolved[msg["request-id"]](msg);
          } else if ((msg.status === 200 || msg.status === 206) && msg.chunk) {
            this.chunk = msg.chunk;
            // Detect if this is the start of a file transfer, and allocate a buffer for it.
            if (msg.chunk.from === 0) {
              this.buffer = new Uint8Array(new ArrayBuffer(this.chunk.size));
            }
          } else if (msg.response === "get" && msg.status !== 206) {
            // all except partial content are considered to be complete
            if (get_resolved[msg["request-id"]])
              get_resolved[msg["request-id"]](msg);
          }
        } catch (err) {
          console.log(`[DC-${id}] DC-RECV JSON PARSE ERROR: ${e.data} ${err}`);
        }
        return;
      }

      // console.log(`[DC-${id}] DC-RECV BINARY: ${e.data}`);

      // Handle file tranfer incoming message chunks

      if (!this.chunk) {
        return;
      }

      // Send a message acknowledging the chunk.
      const ack = {
        command: "ack",
        data: {
          filename: this.chunk.name,
          from: this.chunk.from,
          to: this.chunk.to,
        },
      };
      console.log(`[DC-${id}] DC-SEND:`, JSON.stringify(ack));
      this.globalDc.send(JSON.stringify(ack));

      if (e.data instanceof ArrayBuffer) {
        // Chromium uses ArrayBuffers
        // Copy the new chunk to the buffer at the start offet.
        this.buffer.set(new Uint8Array(e.data), this.chunk.from);

        // Final chunk has arrived
        if (this.chunk.size === this.chunk.to) this.process_file();
      } else if (e.data instanceof Blob) {
        // Firefox uses Blobs.  Use a promise to convert the data to a Uint8Array.
        e.data.arrayBuffer().then((d) => {
          // Copy the new chunk to the buffer at the start offet.
          this.buffer.set(new Uint8Array(d), this.chunk.from);

          // Final chunk has arrived
          if (this.chunk.size === this.chunk.to) this.process_file();
        });
      }
    };

    this.globalDc = dc;
    window.dc = dc;
    return dc;
  }

  process_file() {
    console.log("Download complete: ", this.chunk.name);

    const data = this.buffer;
    const type = this.getMime();

    // Convert the ArrayBuffer to a Blob object
    const blob = new Blob([data], { type });
    const url = URL.createObjectURL(blob);

    // Create an anchor element for the file download
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = this.chunk.name;
    // Programmatically click the anchor element to trigger the file download
    anchor.click();

    // Clean up the temporary Blob URL
    URL.revokeObjectURL(url);

    this.chunk = null;
  }

  // Send command to AWS over websocket requesting that an instance of
  // a DCv2 connection handler is started on the edge device.
  // The edge device will make an offer when it is ready.
  spawn(type) {
    if (!type) type = "dcv2-ftp";
    return new Promise((resolve, reject) => {
      this.resolved = resolve;
      this.rejected = reject;
      this.disconnect();
      this.offerId = DCv2.randomId();
      awsInfo.aws.send_dcv2(this.camId, this.transactionId, {
        dcv2: "spawn",
        type,
        id: this.offerId,
        "user-agent": navigator.userAgent,
        commit: this.git_hash,
        "build-time": this.git_time,
      });
    });
  }

  // Make an offer to the edge device.
  // Usually the edge device makes the offer.
  connect() {
    this.offerId = DCv2.randomId();
    this.globalDc = this.offerPeerConnection(this.offerId);
  }

  // Convenience method to invoke a command on a specific camera in one call.
  static async camdoit(camId, command, filename) {
    const d = new DCv2(camId);
    await d.spawn();
    await d.doit(command, filename);
    await d.disconnect();
  }

  // doit makes an api call and waits for it to complete.
  // Returns the message that was received when the promise was fulfilled.
  async doit(command, filename) {
    return new Promise((resolve) => {
      // Create a random number for this request.
      const id = DCv2.randomId();

      console.log(
        "++++++++++++++++   REQUEST   +++++++++++++++",
        id,
        command,
        filename
      );

      // Using the ID as key, store in an array the resolution to a
      // the promise.   When this request has been completed the ID will be used
      // to retrieve the resolution and execute it.
      get_resolved[id] = (msg) => {
        console.log(
          "=============   RESOLVED    ===============",
          id,
          command,
          filename
        );
        resolve(msg);
      };

      // Prepare the DCv2 API call
      const get = {
        command,
        data: { filename, "auto-close": false },
        "request-id": id,
      };

      // Send API call over dcv2 datachannel
      this.globalDc.send(JSON.stringify(get));

      console.log(`[DC-${this.offerId}] DC-SEND:`, JSON.stringify(get));
    });
  }

  disconnect() {
    if (this.globalDc) {
      this.globalDc.close();
      this.globalDc = null;
    }
    if (this.globalPc) {
      this.globalPc.close();
      this.globalPc = null;
    }
    this.offerId = 0;
    this.description_set = false;
    this.connected = false;

    // Why is $off not working?  It is the same receiveMessage...
    bus.$off("receivedMessageEvent", this.receiveMessage);
  }

  // For file download progressbar.  Not really a percentage, it is the offset
  // within the file where the current chunk finishes in bytes.
  percentage() {
    if (!this.chunk) return 0;
    return this.chunk.to;
  }

  // Return the total file size for the download
  file_size() {
    if (!this.chunk) return 0;
    return this.chunk.size;
  }

  getMime() {
    if (!this.chunk) return null;

    let type;
    if (this.chunk.name.endsWith(".mp4")) {
      type = "video/mp4";
    } else if (this.chunk.name.endsWith(".jpg")) {
      type = "image/jpeg";
    } else if (this.chunk.name.endsWith(".png")) {
      type = "image/png";
    }
    return type;
  }

  // Request info about the server.
  get_info() {
    awsInfo.aws.send_dcv2(this.camId, this.transactionId, {
      dcv2: "info",
      type: "dcv2",
      id: -1,
      "user-agent": navigator.userAgent,
      commit: this.git_hash,
      "build-time": this.git_time,
    });
  }

  // command_line creates the docker run command with the correct parameters and tokens
  // to run the dcv2-net-client program and establish a network connection over the data
  // channel.
  command_line() {
    this.offerId = DCv2.randomId();
    this.transactionId = nanoid();

    // A randomised 10.1.x.y
    const net = "10.1.1";

    // Find an odd random number between 1 and 99.
    const host = Math.floor(Math.random() * 49) * 2 + 1;

    // Server is at the odd number
    const serverIp = `${net}.${host}`;

    // Client is at the next even number
    const clientIp = `${net}.${host + 1}`;

    const json = {};
    json.program = "dcv2-net-client";
    json.id = this.offerId;
    json.camId = this.camId;
    json.sessionId = this.transactionId;
    json.jwt = this.jwt();
    json["client-ip"] = clientIp;
    json["server-ip"] = serverIp;

    return `docker run  --init   --restart=no   --cap-add=NET_ADMIN  -v /dev/net/tun:/dev/net/tun  --device-cgroup-rule='c 10:200 rmw'  --network host   dcv2d  dcv2d  '${JSON.stringify(
      json
    )}'`;

    // return `sudo dcv2-net-client ${this.offerId} ${this.camId} ${this.transactionId} ${this.jwt()}`;
  }

  // Process MQTT messages from the AWS websocket
  receiveMessage(message) {
    const response = message?.data?.webRtcResponse;
    const type = response?.dcv2;

    // Ignore message if they are not identified as DCv2 type messages.
    if (!type) return;

    // Ignore messages if they are for id's other than this one.
    const id = response.id;
    if (id !== this.offerId) {
      return;
    }

    let c;

    switch (type) {
      case "status":
        // Process a DCv2 process status message
        // if (response.result === 'xyz') {}
        break;
      case "answer":
      case "offer":
        // This is the main starting point for data channel connections.

        // Both answers and offers share a common code path.
        // Usually the edge device will make the offer.

        // Create a PeerConnection
        this.globalPc = this.createPeerConnection(id);

        // Set the SDP we received as the remote description.
        this.globalPc
          .setRemoteDescription({
            sdp: response.description,
            type,
          })
          .then(() => {
            this.description_set = true;

            // If we received an offer from the edge device...
            if (type === "offer") {
              // Send an answer
              this.sendLocalDescription(id, this.globalPc, "answer");
            }

            // If any candidates arrived before the SDP, we can now pop them from the list.
            while (this.candidate_list.length > 0) {
              this.globalPc.addIceCandidate(this.candidate_list[0]);
              this.candidate_list.shift();
            }
          });
        break;

      case "candidate":
        c = {
          candidate: response.candidate,
          sdpMid: "0",
          // sdpMid: response.mid, // Why is mid not present in the response?
        };
        console.log(`[DC-${id}] Candidate received: `, c);

        // May have to queue the candidates until later, until the description has been set.
        if (this.description_set) {
          this.globalPc.addIceCandidate(c);
        } else {
          this.candidate_list.push(c);
        }
        break;
      default:
    }
  }
}
export default DCv2;
