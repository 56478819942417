export default {

    state: {
      notifications: [],
    },
    getters: {},
    mutations: {
      pushNotif(state, notif) {
        state.notifications.push({
          ...notif,
          id: Math.random().toString(36) + Date.now.toString(365),
        })
      },
      removeNotif(state, notifToRemove) {
        state.notifications = state.notifications.filter((notif) => notif.id !== notifToRemove.id)
      },
    },
    actions: {
      addNotification({ commit }, notification) {
        commit('pushNotif', notification)
      },
      removeNotification({ commit }, notification) {
        commit('removeNotif', notification)
      },
    },
  }
  