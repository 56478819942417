import { createStore } from 'vuex'

import auth from './auth'
import events from './events'
import notification from './ notification'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    events,
    notification
  }
})
