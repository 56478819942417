export default {
  state: {
    filterBy: null,
    filterTo: null,
    events: [],
    selectedEvent: "",
    rememberedTime: "",
    viewCameraStart: null,
    eventTypes: 0, // 0 = no filter, 1 = car, 2 = human, 3 = car + human
  },
  getters: {
    eventTypes: (state) => state.eventTypes,
    selectedEvent: (state) => state.selectedEvent,
    filterTime: (state) => state.filterBy,
    filterByType: (state) => {
      // Return the event list filtered, either no filter, or c and/or p
      return state.events.filter((e) => {
        console.log("filtering: ", e.data, e.data.t.s, state.eventTypes);
        return (
          state.eventTypes == 0 ||
          (state.eventTypes & 1 && e.data.t.s === "c") ||
          (state.eventTypes & 2 && e.data.t.s === "p")
        );
      });
      //return state.events.filter((e) => { return ((state.eventTypes == 0 ) || state.eventTypes & 1 && e.t.s === "c") || ( state.eventTypes & 2 && e.t.s === "p")});
    },
    eventsReverse: (state, getters) => getters.filterByType.slice().reverse(),
    eventTo: (state) => state.filterTo,
    rememberedTime: (state) => state.rememberedTime,
    viewCameraStart: (state) => state.viewCameraStart,
  },
  mutations: {
    updateSelectedEvent(state, payload) {
      state.selectedEvent = payload;
    },
    updateEvents(state, payload) {
      if (payload === null) {
        state.events = [];
      } else {
        state.events.push(...JSON.parse(payload).events);
      }
    },
    updateFilter(state, payload) {
      state.filterBy = payload;
    },
    updateFilterTo(state, payload) {
      state.filterTo = payload;
    },
    setRememberedTime(state, payload) {
      state.rememberedTime = payload;
    },
    setViewCameraStart(state, payload) {
      state.viewCameraStart = payload;
    },
    cycleEventTypes(state) {
      if (state.eventTypes < 3) {
        state.eventTypes++;
      } else {
        state.eventTypes = 0;
      }
    },
  },
  actions: {
    selectEvent({ commit }, payload) {
      commit("updateSelectedEvent", payload);
    },
    changeEvents({ commit }, payload) {
      console.log("Payload: ", payload);
      commit("updateEvents", payload);
    },
    ChangeFilter({ commit }, payload) {
      const time = Date.now() - payload;

      commit("updateFilter", time);
    },
    changeStartTime({ commit }, payload) {
      commit("updateFilter", payload);
    },
    changeEndTime({ commit }, payload) {
      commit("updateFilterTo", payload);
    },
  },
};
  