<template>
  <div class="WebConsole">
    <div class="status">Status: {{ status }}</div>
    <div class="title">Device: {{ deviceName }}</div>
    <div ref="terminal"></div>
  </div>
</template>

<script>
import DCv2 from "@/utils/DCv2";
import { Terminal } from "xterm";
import "xterm/css/xterm.css"; // don't forget to import the xterm styleso
import { WebglAddon } from "xterm-addon-webgl";
import bus from "@/utils/EventBus";

function blobToUint8Array(blob) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      resolve(new Uint8Array(reader.result));
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
}

export default {
  name: "DCv2-WebConsole",
  props: {
    camId: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      dcv2: null,
      term: null,
      status: null,
    };
  },

  async mounted() {
    bus.$on("dcv2-pty-status", this.receiveStatus);

    this.status = "Initialising...";
    this.dcv2 = new DCv2(this.camId);
    await this.dcv2.spawn("dcv2-pty");

    setInterval(() => {
      if (this.dcv2) this.dcv2.getConnectionType();
    }, 5000);

    // Set the terminal emulator up
    this.term = new Terminal();
    const webglAddon = new WebglAddon();
    this.term.loadAddon(webglAddon);

    // Any data received from the terminal emulator is to be sent out the data channel
    this.term.onData((data) => {
      if (this.dcv2.globalDc) this.dcv2.globalDc.send(data);
    });

    this.term.open(this.$refs.terminal);
    this.term.options.cursorBlink = true;
    this.term.options.fontSize = 15;
    this.term.options.enableBold = true;
    this.term.options.screenKeys = true;

    // Receive messages from the peer over datachannel
    this.dcv2.globalDc.onmessage = (msg) => {
      // Cross browser variations...
      if (msg.data instanceof Blob) {
        // Firefox
        blobToUint8Array(msg.data)
          .then((uint8Array) => {
            this.term.write(uint8Array);
          })
          .catch((error) => {
            console.error(
              "An error occurred while converting the blob to Uint8Array: ",
              error
            );
          });
      } else {
        // Chrome
        this.term.write(new Uint8Array(msg.data));
      }
    };
  },
  beforeUnmount() {
    try {
      if (this.term) {
        this.term.dispose();
        this.term = null;
      }
    } catch (e) {
      console.log("" + e);
    }
    this.dcv2.disconnect();
    bus.$off("dcv2-pty-status", this.receiveStatus);
  },

  computed: {
    deviceName() {
      return this.deviceFind(this.camId)?.name;
    },
  },
  methods: {
    receiveStatus() {
      this.status = this.dcv2.getStatus();
    },
    deviceFind(camId) {
      let device;
      this.$parent.devices.forEach((element) => {
        element.cameras.forEach((camera) => {
          if (camera.id === camId) {
            device = element;
          }
          return "no";
        });
      });
      return device;
    },
  },
};
</script>

<style type="scss" scoped>
.title {
  color: white;
  margin-left: 20px;
  margin-bottom: 5px;
  text-decoration: underline;
}

.status {
  color: white;
  float: right;
  margin-right: 48px;
  margin-bottom: 5px;
}
</style>
