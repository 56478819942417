<template>
    <div class="card" :class="typeClass" @click="removeNotif">
        <h1>{{ notif.title }}</h1>
        <p>{{ notif.comment }}</p>
    </div>
</template>

<script>
export default {
  props: ['notif'],
  computed: {
    typeClass() {
      return `${this.notif.type}`
    },
  },
  methods: {
    removeNotif() {
      this.$store.dispatch('removeNotification', this.notif)
    },
  },
  created() {
    setTimeout(() => {
      this.$store.dispatch('removeNotification', this.notif)
    }, 4000);
  },
};
</script>

<style scoped>
h1,
p {
    margin: 0;
    padding: 0;
    text-align: center;
}
h1 {
    font-size: 18px;
    font-weight: 400;
}
p {
    font-size: 12px;
}
</style>
