<template>
  <div class="LibPeer">

      <img id="imgStream">
      <!-- <video id="videoStream" playsinline style="width:100%; display: none"></video> -->
      <!-- <audio id="audioStream" style="display: none"></audio> -->
 
  </div>
</template>

  <!-- <script src="https://kit.fontawesome.com/8c8bbe3334.js" crossorigin="anonymous"></script> -->

<script>

import awsInfo from "@/utils/websocket";
import { nanoid } from "nanoid";
import bus from "@/utils/EventBus";


var pc; 
var datachannel;
var timer;
var answerId = null;
var transactionId = null;
var camId = null;


export default {
  name: "LibPeer",
  props: {
    camId: {
      type: String,
      required: true,
      default: "K45dTfGVrP8QLsQzAAk1f",
    },
  },
  data() {
    return {
      transactionId: null,
      offerId: null,
    };
  },
  computed: {
  },
  methods: {
    setup_libpeer(deviceId) {
      this.transactionId = nanoid();
      transactionId = this.transactionId;

      console.log("Setup LibPeer device id =", deviceId);

      if (deviceId == undefined) {
        return;
      }

      pc = new RTCPeerConnection({
        iceServers: [
          {
            urls: "stun:megakam.com:3478",
          },
          {
              urls: 'turn:megakam.com:3478?transport=udp',
              username: 'myuser',
              credential: 'mypass',
          }
        ],
      });

      datachannel = pc.createDataChannel('pear');

      pc.ontrack = function (event) {
        if (event.track.kind == 'video') {

          let el = document.getElementById('videoStream');
          let newStream = new MediaStream();
          newStream.addTrack(event.track);
          el.srcObject = newStream;
          el.autoplay = true
          el.controls = false
          el.muted = true
          document.getElementById('imgStream').style.display = 'none';
          document.getElementById('videoStream').style.display = 'block';

        } else if (event.track.kind == 'audio') {

          let el = document.getElementById('audioStream');
          let newStream = new MediaStream();
          newStream.addTrack(event.track);
          el.srcObject = newStream;
          el.controls = false
          el.muted = false

        }
      }

      pc.oniceconnectionstatechange = e => {
        console.log(pc.iceConnectionState, e)
      }

      pc.ondatachannel = () => {
        console.log('ondatachannel');
      }

      datachannel.onclose = () => console.log('datachannel has closed');

      datachannel.onopen = () => {
        console.log('datachannel has opened');
        timer = setInterval(() => {
          // console.log('sending ping');
          datachannel.send('ping');
        }, 10000);
      }

      datachannel.onmessage = e => {

        // The data is binary jpeg data.
          
        if (!e.data) return;
//        console.log("DC RECV:", e.data);

        var urlCreator = window.URL || window.webkitURL;

        var blob;
        if (e.data instanceof ArrayBuffer) {
          var arrayBufferView = new Uint8Array(e.data);
          blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        }
        else {
          blob = e.data
        }

        //  urlCreator = URL;
        var imageUrl = urlCreator.createObjectURL(blob);

        var imageElement = document.getElementById('imgStream');
        imageElement.src = imageUrl;
      }

      pc.onicecandidate = event => {
        if (event.candidate === null) {
          answerId = Math.floor((Math.random() * 1000) + 1);
          console.log(pc.localDescription.sdp)
          let json = {
            jsonrpc: '2.0',
            method: 'answer',
            params: pc.localDescription.sdp,
            id: answerId,
          }
          console.log(json)
          awsInfo.aws.send_dcv2(camId, transactionId, json);

          /*
          setInterval(() => {
      
            pc.getStats(null).then((stats) => {
              let statsOutput = "";
      
              stats.forEach((report) => {
                statsOutput +=
                  `<h2>Report: ${report.type}</h2>\n<strong>ID:</strong> ${report.id}<br>\n` +
                  `<strong>Timestamp:</strong> ${report.timestamp}<br>\n`;
                // Now the statistics for this report; we intentionally drop the ones we
                // sorted to the top above
      
                Object.keys(report).forEach((statName) => {
                  if (
                    statName !== "id" &&
                    statName !== "timestamp" &&
                    statName !== "type"
                  ) {
                    statsOutput += `<strong>${statName}:</strong> ${report[statName]}<br>\n`;
                  }
                });
              });
      
              //document.getElementById("stats-box").innerHTML = statsOutput;
              console.log(statsOutput);
              statsOutput;
            });
          }, 1000);
          */

        }
      }
      this.offerId = Math.floor((Math.random() * 1000) + 1);

      console.log('publish offer to ' + deviceId)

      const m = {
        jsonrpc: '2.0',
        method: 'offer',
        id: this.offerId,
      };

      awsInfo.aws.send_dcv2(this.camId, this.transactionId, m);
    },


    // Process MQTT messages from the AWS websocket
    receiveMessage(message) {
      console.log("LIBPEER MQTT RECV", message);
      const response = message?.data?.webRtcResponse;
    //  const type = response?.dcv2;

      // Ignore message if they are not identified as DCv2 type messages.
    //  if (!type) return;

      // Ignore messages if they are for id's other than this one.
      const id = response.id;
      if (id !== this.offerId) {
        return;
      }

      // Set up libpeer

      let msg = response;
      if (msg.id == this.offerId) {

        let sdp = msg.result;
        let offer = { type: 'offer', sdp: sdp };

        console.log(offer)
        pc.setRemoteDescription(offer);
        pc.createAnswer().then(d => pc.setLocalDescription(d)).catch(console.log)

      } else if (msg.id == answerId) {

        console.log('receive answer ok')
      }
    },
  },
  mounted() {
    this.setup_libpeer(this.camId);
    camId = this.camId;
    bus.$on("receivedMessageEvent", this.receiveMessage);
  },
  beforeUnmount() {
    if( timer )
      clearInterval(timer);

    if (datachannel) {
      awsInfo.aws.send_dcv2(this.camId, this.transactionId,
        {
          jsonrpc: '2.0',
          method: 'close',
          id: Math.floor((Math.random() * 1000) + 1),
        });

      datachannel.close();
    }
    datachannel = null;

    if (pc) {
      pc.oniceconnectionstatechange = null;
      pc.onicegatheringstatechange = null;
      pc.close();
    }
    pc = null;

    console.log("UNMOUNT - cleaned out.");
    bus.$off("receivedMessageEvent", this.receiveMessage);
  },
};
</script>

<style scoped>
button {
  margin: 10px;
  padding: 10px;
  color: red;
}

img {
  border: 0px solid red;
}

.spinner {
  display: inline-block;
  justify-content: center;
  align-items: center;
  height: 32px;
}

.spinner-inner {
  width: 30px;
  height: 30px;
  border: 3px solid #999;
  border-top-color: #666;
  border-radius: 50%;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

</style>

