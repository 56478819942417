<template>
  <div class="player-page">
    <div class="movie-maker-wrapper" :class="{ hidden: !showMovieModal }">

      <div id="movie-maker-modal" ref="clipMakerModal">
        <p class="modal-title" v-if="clipModalMode !== 'downloaded'">Clip Maker</p>
        <div class="modal-body" v-if="clipModalMode=='creating'">
          <div class="modal-wrapper start-wrapper">
            <p class="modal-subtitle">Start Time</p>
            <div class="modal-group start-group">
              <button @click="decrementStartTime" :disabled="disableClipElements">-</button>
              <input type="datetime-local" v-model="startTime" :disabled="disableClipElements" />
              <button @click="incrementStartTime" :disabled="disableClipElements">+</button>
            </div>
          </div>
          <div class="modal-wrapper end-wrapper">
            <p class="modal-subtitle">End Time</p>
            <div class="modal-group end-group">
              <button @click="decrementEndTime" :disabled="disableClipElements">-</button>
              <input type="datetime-local" v-model="endTime" :disabled="disableClipElements" />
              <button @click="incrementEndTime" :disabled="disableClipElements">+</button>
            </div>
          </div>

        </div>

        <div class="modal-body modal-body-checkmark" v-else>
          <div class="checkmark-box">
            <div class="checkmark"></div>
            <h2>Download Complete</h2>
          </div>
        </div>

        <div class="modal-footer" v-if="clipModalMode=='creating'">
          <button class="modal-close" @click="closeClipMaker">Cancel</button>
          <button class="modal-accept" @click="createClip" :disabled="downloadingClip"> 
            <span :class="{ 'hide-download-text': this.downloadingClip }">Download Clip</span>
            <LoadingWheel class="download-indicator" v-if="downloadingClip" />
          </button>
        </div>
        
      </div>

      <div class="movie-maker-modal-backdrop" @click="closeClipMaker">
      </div>

    </div>
    
      <div class="sidebar">
          <nav>
              <img class="brand-image" src="@/assets/logo.png" />
              <div class="nav-wrapper">
                <div class="git-info">
                  <span class="version">Version: {{ version }}</span>
                  <span class="time">Git Time: {{ time }}</span>
                </div>
                <button class="manage-camera-btn" @click="manageCameras"></button>
                <button class="logout-btn" @click="logout"></button>
              </div>
          </nav>
          <div class="events">
              <div class="showIFrameMode" v-if="showIFrameMode">
                Control I-frame mode: {{ controlIFrameMode}}
              </div>

              <div class="showHolywoodMessage" v-if="showHolywoodMessage">
                Holywood mode: {{ holywoodMode }}
              </div>

              <div class="showEventTypesMessage" v-if="showEventTypesMessage">
                Event filter: {{ eventTypes }}
              </div>

              <p class="title">Event Inbox</p>
              <p style="color: white;" v-if="camera !== null && camera !== undefined">{{ this.camera.name }}</p>
              <div class="event-list" :key="eventsReversed">
                  <div class="event center" v-if="eventsReversed.length == 0">
                    No events to display
                  </div>
                  <div class="event-group" v-else v-for="(event, index) in eventsReversed" :key="'event-' + index">
                    <div class="event"  @click="startEventPlayback(event)">
                      <div class="icon-group">
                        <img class="event-icon" :src="eventIcon(event)" />
                        <p>{{eventLabel(event)}}</p>
                      </div>
                      <div class="info">
                          <p>{{ getEventDuration(event) }}</p>
                          <div class="spacer"></div>
                          <p> {{ getEventStartTime(event) }}</p>
                      </div>
                    </div>
                    <button class="clip-btn" @click="openClipMaker(event)"></button>

                  </div>
              </div>
              <div class="date-buttons" v-if="showDateButtons">
                <button @click="offsetInc">&lt;</button>
                <span class="date-text" style="color: white;">{{ displayDate() }}</span>
                <div class="right-wrapper" :class="{ 'disable-right-buttons': this.dayOffset == 0}">
                  <button class="offsetDec" @click="offsetDec" :disabled="this.dayOffset == 0">&gt;</button>
                  <button @click="offsetReset" :disabled="this.dayOffset == 0">now</button>
                </div>
              </div>
          </div>
      </div>
      <div class="livestream">
          <LiveVideo ref="liveVideo" :camera="camera" @cameraLoading="setCameraLoading" @seekTime="updateEvents" @startEventPlayback="startEventPlayback"
          @playmode="lvPlaymode" @clipStatus="handleClipStatus" @sessionId="setSessionId" @openClipMaker="openClipMakerCurrentTime"
          v-if="camera !== null" :holywoodMode="holywoodMode" :controlIFrameMode="controlIFrameMode" />
          <WebConsole :camId="consoleCamId" ref="webconsole" v-else-if="consoleCamId"/>
          <LibPeer :camId="libpeerCamId" ref="libpeer" v-else-if="libpeerCamId"/>
          <div class="no-cam-wrapper" v-else>
            <img class="no-cam-icon" src="@/assets/camera-off-24-filled.svg" height="75" />
            <h1 class="no-cam-text">Please select a camera below to view the stream.</h1>
          </div>
      </div>
      <div class="cameras">
            <p class="title">Cameras</p>
            <div class="device-list">
                <LoadingWheel v-if="loading == true" class="loading-indicator" />
                <div class="device" v-for="(device, deviceIndex) in devices" :key="'device-' + deviceIndex">
                    <p>{{ device.name }}</p>
                    <div class="device-cameras">
                        <div class="camera" v-for="(camera, camIndex) in device.cameras" :key="'camera-' + camIndex" @click="viewLiveStream(camera)">
                            <div class="cameraImage">
                              <img class="hidden" v-bind:key="camera.id" v-if="camera.state === 'active'"
                              v-shown="loadImage" alt="camera" />
                              <img v-else src="@/assets/videoOff.svg" alt="camera off" />
                            </div>
                            <p>{{ camera.name }}</p>
                        </div>
                        <!-- <div v-if="device.cameras[0]" class="camera" @click="viewLibPeer(device)">
                          <div class="cameraImage">
                            <img src="@/assets/videoIcon.svg" alt="Lib Peer" style="scale:0.6;"/>
                          </div>
                          <p>ESP-32</p>
                      </div>
                        <div v-if="device.cameras[0]" class="camera" @click="viewWebConsole(device)">
                          <div class="cameraImage">
                            <img src="@/assets/webconsole.svg" alt="Web Console" />
                          </div>
                          <p>Web Console</p>
                      </div> -->
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>

import EventBus from '@/utils/EventBus';
import aws_info from '@/utils/websocket';

import LiveVideo from '@/components/LiveVideo.vue';
import LoadingWheel from '@/components/LoadingWheel.vue';

import activeCamIcon from '@/assets/videoIcon.svg';

import { get } from 'idb-keyval';

import { toDatetimeLocal } from '@/utils/DateTime'

import { createMP4Clip } from '@/utils/messaging/MediaMakerMessages';

import DCv2 from '@/utils/DCv2'

import { nextTick } from "vue"
import WebConsole from '@/components/WebConsole.vue';
import LibPeer from '@/components/LibPeer.vue';

export default {
  name: 'HomeView',
  components: {
      LiveVideo,
      LoadingWheel,
      WebConsole,
      LibPeer,
  },
  directives: {
    shown: {
      mounted: async (el, binding, vnode) => {
        //OLD: const func = vnode.context[binding.expression];
        const func = binding.value
        if (typeof (func) === 'function') func(el, vnode.key);
      },
    },
  },
  data () {
      return {
          events: Array.from(Array(10).keys()),
          devices: [],
          camera: null,
          consoleCamId: null,
          libpeerCamId: null,
          loading: true,
          dayOffset: 0,
          cameraLoading: true,
          showMovieModal: false,
          startTime: '',
          endTime: '',
          downloadingClip: false,
          movieClipStatus: 'not-created',
          movieClipTimer: -1,
          dcv2: null,
          sessionId: '',
          clipModalMode: 'creating',
          controlIFrameMode: false,
          showIFrameMode: false,
          showHolywoodMessage: false,
          holywoodMode: 3,
          showEventTypesMessage: 0, 
      }
  },
  mounted () {

      EventBus.$on('receivedMessageEvent', this.receivedMessageEvent)
      aws_info.list()
      this.loading = true
      window.get_file = this.get_file

      window.addEventListener('keypress', this.handleKeys)
  },
  beforeUnmount () {
      EventBus.$off('receivedMessageEvent', this.receivedMessageEvent)
      if (this.dcv2 !== null) {
        if (this.dcv2.isConnected())
          this.dcv2.disconnect()
      }

      window.removeEventListener('keypress', this.handleKeys)
  },
  methods: {
    handleKeys(event) {
      let code = event.code
      switch (code) {
        case 'KeyH': // Enable / disable object detected rendering
          this.holywoodMode++;
          if (this.holywoodMode > 3) this.holywoodMode = 0;
          break;

        case 'KeyI':
          this.controlIFrameMode = !this.controlIFrameMode;
          break;

        case 'KeyE':
          this.$store.commit('cycleEventTypes');
          console.log("event types = ", this.eventTypes);
          this.showEventTypesMessage = true;

          setTimeout(() => {
            this.showEventTypesMessage = false;
          }, 1000);
          break;
      }
    },
 
    viewLibPeer(device) {
      if (!device.cameras[0])
        return;
      this.consoleCamId = null;
      this.camera = null;
      this.libpeerCamId = null;
      const camId = device.cameras[0].id;
      console.log("Starting lib peer for camera", camId);
      // Allow time for the old console to be removed after setting it to null
      nextTick(() => {
        this.libpeerCamId = camId;
       });
    },
    viewWebConsole(device) {
      if (!device.cameras[0])
        return;
      this.consoleCamId = null;
      this.libpeerCamId = null;
      this.camera = null;
      const camId = device.cameras[0].id;
      console.log("Starting web console for camera", camId);
      // Allow time for the old console to be removed after setting it to null
      nextTick(() => {
      this.consoleCamId = camId;
       });
    },
      async receivedMessageEvent (message) {
          if (message.response == "GetDeviceList") {
              const sortAlphaNum = (a, b) => a.name.localeCompare(b.name, 'en', { numeric: true })
              this.devices = message.data.devices.sort(sortAlphaNum)
              this.loading = false
          }
      },
      viewLiveStream(camera) {        
          if (this.camera == camera)
            return

          if (this.dcv2 !== null) {
            if (this.dcv2.isConnected())
              this.disconnect()
          }
          
          this.closeClipMaker()

          this.camera = null
          this.consoleCamId = null;
          this.libpeerCamId = null;
          this.$nextTick(() => {

            this.camera = camera
            aws_info.liveView(this.camera.id)
            this.$store.dispatch('changeEvents', null)

            const date = new Date(new Date().toDateString())
            const timeZoneOffset = date.getTimezoneOffset()

            // Calculate the Unix epoch time for the start and end of the day
            const startOfDay = date.getTime() - (date.getHours() * 60 + date.getMinutes()) * 60 * 1000 - date.getSeconds() * 1000 - date.getMilliseconds() - timeZoneOffset * 60 * 1000
            let endOfDay = startOfDay + (86400000 - 1)
            if (endOfDay -1 > Date.now())
              endOfDay = Date.now()
            this.$store.dispatch('changeEndTime', endOfDay)
            this.$store.dispatch('changeStartTime', startOfDay)

            this.dcv2 = new DCv2(this.camera.id)

          })
      },
      getEventStartTime(event) {
        const date = new Date(event.data.s)
        let mins = date.getMinutes() > 9 ? date.getMinutes().toString() : '0' + date.getMinutes() 
        return [date.getHours(), mins].join(':')
      },
      getEventDuration (event) {
        let start = event.data.s
        let end = event.data.e
        let durationMs = end - start
        return this.formatDuration(durationMs)
      },
      // ChatGPT wrote this
      formatDuration(milliseconds) {
        // Calculate the duration in various units

        // Create an array of strings to hold the duration components
        const components = [];
        // Add days to the components if applicable
        const days = Math.floor(milliseconds /  (24 * 60 * 60 * 1000));
        if (days > 0) {
          components.push(`${days}d`);
          milliseconds -= days * 24 * 60 * 60 * 1000;
        }

        // Add hours to the components if applicable
        const hours = Math.floor(milliseconds / (60 * 60 * 1000));
        if (hours > 0) {
          components.push(`${hours}h`);
          milliseconds -= hours * 60 * 60 * 1000;
        }

        // Add minutes to the components if applicable
        const minutes = Math.floor(milliseconds / (60 * 1000));
        if (minutes > 0) {
          components.push(`${minutes}m`);
          milliseconds -= minutes * 60 * 1000;
        }

        // Add seconds to the components if applicable
        const seconds = Math.floor(milliseconds / 1000);
        if (seconds > 0) {
          components.push(`${seconds % 60}s`);
        }

        // If no components were added, return "0s"
        if (components.length === 0) {
          return "0s";
        }

        // Combine the components into a single string
        if (components.length === 1) {
          return components[0];
        } else {
          return components.join(" ");
        }
      },
      eventType(event) {
        switch (event.data.t.s) {
          case 'e':
            return 'external';
          case 'm':
            return 'motion';
          case 'v':
            return 'car';
          case 'p':
            return 'human';
          default:
            return 'generic';
        }
      },
      eventIcon(event) {
        let icon = this.eventType(event);

        switch (icon) {
          case "human": icon = 'person-walking'; break;
          case "car": icon = 'car'; break;
          case "external": icon = 'external'; break;
          case "motion": icon = 'motion'; break;
          default: icon = 'generic'; break;
        }

        let images = require.context('../assets/', false, /\.svg$/)

        return images('./' + icon + ".svg")
      },
      eventLabel(event) {
        let label = this.eventType(event);

        switch (label) {
          case "human": label = 'Person'; break;
          case "car": label = 'Car'; break;
          case "external": label = 'External'; break;
          case "motion": label = 'Motion'; break;
          default: label = 'Generic'; break;
        }

        return label;
      },
       startEventPlayback (event) {
        if (event.data.s === 'No events available') {
          return
        }
        this.$store.dispatch('selectEvent', event.data.s)
        setTimeout(() => {
          this.$store.dispatch('selectEvent', null)
        }, 100)

        let time = toDatetimeLocal(new Date(event.data.s))

        this.$store.commit('setRememberedTime', time)
    },

    offsetInc () {
      this.dayOffset += 1
    },
    offsetDec () {
      if (this.dayOffset > 0)
        this.dayOffset -= 1
    },
    offsetReset () {
      this.dayOffset = 0
    },

    changeDate () {
      this.$store.dispatch('changeEvents', null)

      const date = this.eventDate
      const timeZoneOffset = date.getTimezoneOffset()
      // Calculate the Unix epoch time for the start and end of the day
      const startOfDay = date.getTime() - (date.getHours() * 60 + date.getMinutes()) * 60 * 1000 - date.getSeconds() * 1000 - date.getMilliseconds() - timeZoneOffset * 60 * 1000
      let endOfDay = startOfDay + (86400000 - 1)
      if (endOfDay -1 > Date.now())
        endOfDay = Date.now()
      this.$store.dispatch('changeEndTime', endOfDay)
      this.$store.dispatch('changeStartTime', startOfDay)
    },
    setCameraLoading (value) {
      this.cameraLoading = value
    },
    updateEvents (date) {
      const date1 = new Date(date.toDateString()); // start date
      const date2 = new Date(new Date().toDateString()); // end date

      const diffInMilliseconds = date2 - date1;
      const diffInDays = Math.round(Math.abs(diffInMilliseconds) / (1000 * 60 * 60 * 24)) // rounding the result to the nearest integer
      this.dayOffset = diffInDays
    },
    lvPlaymode (value) {
      if (value == 'lv') {
        this.offsetReset()
      }
    },
    displayDate () {
      return new Intl.DateTimeFormat('en-GB', { weekday: 'short', day: '2-digit', month: 'long', year: 'numeric' }).format(this.eventDate).replaceAll(',', '')
    },
    logout () {
      this.$router.push({ name: 'logout' })
    },
    manageCameras() {
      this.$router.push({ name: 'manage-cameras'})
    },
    async loadImage(element, id) {
      console.log('Load Image')
      const image = await this.getImage(id);
      if (image === null || image === undefined) {

        let images = require.context('../assets/', false, /\.svg$/)
        element.setAttribute('src', images('./' + 'videoIcon' + ".svg"));
        element.setAttribute('class', 'preview');

      } else {
        element.setAttribute('src', image);
        const imageClass = await this.hasImage(id) ? 'preview' : '';
        element.setAttribute('class', imageClass);
      }
      
    },
    async getImage(id) {
      const image = await get(`camerapreview.${id}`); // localStorage.getItem(`camerapreview.${id}`);
      return (image != null || image !== undefined) ? image : activeCamIcon;
    },
    async hasImage(id) {
      return (await get(`camerapreview.${id}`)) != null;
    },
    parseDateTime(str) {
        let data = str.split(' ')
        let date = data[0].split('.').reverse().join('/')
        let time = data[1]
        return new Date(`${date} ${time} GMT`)
      },
    openClipMakerCurrentTime(currentDate) {

      let date = this.parseDateTime(currentDate)
      let time = date.getTime()

      if (Date.now() - time < 5000) {

        let endDate = new Date(Date.now())
        let startDate = new Date(Date.now() - 10000)

        this.startTime = toDatetimeLocal(startDate)
        this.endTime = toDatetimeLocal(endDate)

      } else {

        let date = this.parseDateTime(currentDate)

        let startDate = new Date(date.getTime() - 5000)
        let endDate = new Date(date.getTime() + 5000)

        this.startTime = toDatetimeLocal(startDate)
        this.endTime = toDatetimeLocal(endDate)
      
      }

      this.clipModalMode = 'creating'
      this.showMovieModal = true

      
    },
    openClipMaker (event) {
      let startDate = new Date(event.data.s)
      let endDate = new Date(event.data.e)

      if (endDate < (startDate.getTime() + 4000)) {
        endDate = new Date(startDate.getTime() + 4000)
      }

      this.startTime = toDatetimeLocal(startDate)
      this.endTime = toDatetimeLocal(endDate)

      this.clipModalMode = 'creating'
      this.showMovieModal = true
    },
    closeClipMaker () {

      this.showMovieModal = false
      this.startTime = ''
      this.endTime = ''
      this.downloadingClip = false

      if (this.movieClipTimer !== -1)
        this.stopMovieClipTimer()

      this.movieClipStatus = 'not-created'
    },
    async createClip() {

      if (this.movieClipStatus == 'complete') {
        this.downloadingClip = true
        await window.get_file(this.sessionId + '.mp4')
        this.clipModalMode = 'downloaded'
        setTimeout(this.closeClipMaker, 2500)
        this.closeClipMaker()
        return
      }

      if (!this.clipTimesValid)
        return false
      
      let start = Date.parse(this.startTime)
      let end = Date.parse(this.endTime)

      if (isNaN(start) || isNaN(end))
        return

      this.downloadingClip = true

      const message = createMP4Clip(this.camera.id, `${this.sessionId}.mp4`, start, end)
      this.$refs.liveVideo.sendData(message)

      this.startMovieClipTimer()

      //this.closeClipMaker()
    },

    decrementStartTime () {
      
      let date = new Date(this.startTime)
      date.setSeconds(date.getSeconds() - 1)
      this.startTime = toDatetimeLocal(date)
    },
    incrementStartTime () {

      let date = new Date(this.startTime)
      date.setSeconds(date.getSeconds() + 1)
      let endDate = new Date(this.endTime)

      if (endDate.getTime() - date.getTime() > 4000) {
        this.startTime = toDatetimeLocal(date)
        return
      }

      endDate = new Date(date.getTime() + 4000)

      if (endDate.getTime() <=  Date.now()) {
        this.startTime = toDatetimeLocal(date)
        this.endTime = toDatetimeLocal(endDate)
      }

    },
    decrementEndTime () {

      let startDate = new Date(this.startTime)
      
      let date = new Date(this.endTime)
      date.setSeconds(date.getSeconds() - 1)
      if (date.getTime() - startDate.getTime() >= 4000) {
        this.endTime = toDatetimeLocal(date)
      }

    },
    incrementEndTime () {

      let date = new Date(this.endTime)
      date.setSeconds(date.getSeconds() + 1)
      if (date.getTime() <= Date.now()) {
        this.endTime = toDatetimeLocal(date)
      }

    },
    async handleClipStatus (status) {

      if (this.movieClipStatus !== status) {
        this.movieClipStatus = status
      }

      if (this.movieClipStatus == 'complete') {
        if (this.movieClipTimer !== -1) {
          this.stopMovieClipTimer()
          await window.get_file(this.sessionId + '.mp4')
          this.downloadingClip = false
          this.clipModalMode = 'downloaded'
          setTimeout(this.closeClipMaker, 2500)
        }
      }

    },
    startMovieClipTimer () {
      this.movieClipTimer = setInterval(() => {

        const startDate = new Date(this.startTime)
        const endDate = new Date(this.endTime)

        const message = createMP4Clip(this.camera.id, `${this.sessionId}.mp4`, startDate.getTime(), endDate.getTime())
        this.$refs.liveVideo.sendData(message)

      }, 1000)
    },
    stopMovieClipTimer () {
      clearInterval(this.movieClipTimer)
      this.movieClipTimer = -1
    },
    async get_file(filename) {
      await this.dcv2.spawn();
      await this.dcv2.doit("get", filename);
      await this.dcv2.doit("delete", filename);
      await this.dcv2.disconnect();
    },
    async doJpegCapture(ts) {
      console.log("Make JPEG process begins at", ts );
      const filename = "" + ts + ".jpg"

      // Send message to make-j
      const msg = {
        topic: 'cmd/devices/hilary-dev/data-channel-relay',
        "destination": "mmctrl",
        "action": {
          "command": "make-j",
          "data": {
            "output": filename,
            "source": this.camera.id,
            "ts": {
              "s": ts,
              "iframesonly": false,
              "qp": 0
            },
            "rtc-osd": {
              "x": 65, "y": 95, "height": 3,
              "time-adjust": -3600, "text": "%Y/%m/%d %H:%M:%S"
            },
            "redaction-zones": [
            ],
            "text-osd": [
              {
                "text": "", "x": 40.2, "y": 10,
                "height": 9
              }
            ]
          }
        }
      };

      this.$refs.liveVideo.sendData(msg);

    },
    async mmctrl_ok(data) {
      // Initiate file download
      await this.get_file(data?.data?.output);
      console.log("Make JPEG process complete.");
    },
    setSessionId(id) {
      this.sessionId = id
    }
  },
  computed: {
      eventTypes() {
        return this.$store.getters.eventTypes;
      },
      eventsReversed() {
          return this.$store.getters.eventsReverse;
      },
      eventDate () {
        let myDate = new Date()
        myDate.setDate(myDate.getDate() - this.dayOffset);
        return myDate
      },
      showDateButtons () {
        return (this.camera !== null && this.camera !== undefined) && this.cameraLoading == false
      },
      version () {
        return process.env.VUE_APP_GIT_HASH
      },
      time () {
        let date = new Date(process.env.VUE_APP_GIT_TIME.replace(/-/g, "/"))
        return new Intl.DateTimeFormat('en-GB', {day: '2-digit', month: 'long', year: 'numeric', hours:'2-digit', hour: 'numeric', minute: 'numeric' }).format(date).replaceAll(',', '').replaceAll('at', '')
      },
      disableClipElements () {
        return this.downloadingClip == true || this.movieClipStatus == 'ok' || this.movieClipStatus == 'complete'
      },
      clipTimesValid() {
        
        if (this.startTime == '' || this.startTime == null || this.startTime == undefined)
          return false

        if (this.endTime == '' || this.endTime == null || this.endTime == undefined)
          return false

        const start = Date.parse(this.startTime)
        const end = Date.parse(this.endTime)

        if (isNaN(start) || isNaN(end))
          return false

        let now = Date.now()

        if (now < start || now < end)
          return

        if (start > end || (end - 4000) < start) 
          return false

        return true
      }
  },
  watch: {
    eventDate () {
      if (this.loading == false)
        this.changeDate()
    },
    holywoodMode() {
        this.showHolywoodMessage = true;

        setTimeout(() => {
          this.showHolywoodMessage  = false;
        }, 1000);
    },
    controlIFrameMode() {
        this.showIFrameMode = true;

        setTimeout(() => {
          this.showIFrameMode  = false;
        }, 1000);
    }
  }
}
</script>

<style scoped>
.player-page {
  height: 100%;
  width: 100%;
  background: black;
}

nav {
  height: 75px;
  width: 350px;
  border-bottom: 2px rgba(255,255,255,0.2) solid;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
}

.brand-image {
  height: 50px;
  z-index: 2;
}

.sidebar {
  width: 350px;
  height: 100%;
  border-right: 2px rgba(255,255,255,0.2) solid;
  float: left;
  box-sizing: border-box;
}

.events {
  padding: 20px;
  height: calc(100% - 75px);
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 0px;
}

.livestream {
  height: 50%;
  width: calc(100% - 350px);
  float: right;
  box-sizing: border-box;
  border-bottom: 2px rgba(255,255,255,0.2) solid;
}

.title {
  font-size: 1.75em;
  color: rgba(255, 255, 255, 0.9);
}

.cameras {
  box-sizing: border-box;
  width: calc(100% - 350px);
  padding: 20px;
  float: right;
  height: 50%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
}


.event {
  background: rgba(219, 242, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 15px;
  box-sizing: border-box;
}

.event .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event .spacer {
  height: 5px;
  width: 5px;
  border-radius: 100px;
  background-color: rgba(219, 242, 255, 0.75);
  margin-left: 7px;
  margin-right: 7px;
}

.event.center {
  justify-content: center;
}

.event:last-child {
  margin-bottom: 0px;
}

.event-list {
  margin-top: 10px;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.cameras {
  color: white;
}

.device-list {
  margin-top: 20px;
  /* background-color: blue; */
  overflow-y: auto;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.device-cameras {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
  flex-wrap: wrap;
}

.icon {
  height: 128px;
  width: 128px;
  background-color: #ccc;
  border-radius: 10px;
  margin-bottom: 10px;
}

.camera {
  margin-right: 20px;
  margin-bottom: 20px;
}

.icon-group {
  display: flex;
  align-items: center;
}

.icon-group .event-icon {
  margin-right: 20px;
}

.no-cam-wrapper {
  color: white;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-cam-text {
  margin-top: 20px;
  font-size: 1.5em;
  text-align: center;
}

.device-list .loading-indicator {
  align-self: center;
  justify-self: center;
}

.date-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-top: 20px;
}

.date-buttons button {

  background: rgba(219, 242, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 10px 15px;
  color: white;

}

.offsetDec { 
  margin-right: 10px;
}

.disable-right-buttons {
  opacity: 0.5;
}

.date-text {
  text-align: center;
}

.right-wrapper {
  display: flex;
}

nav:hover .brand-image {
  animation: myAnim 1s ease 0s 1 normal forwards;
}

@keyframes myAnim {
	0% {
		opacity: 1;
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		transform: translateY(-250px);
	}
}

.nav-wrapper {
  /* background-color: red; */
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  display: none;
}

nav:hover .nav-wrapper {
  display: flex;
  animation: fadeIn 1s ease 0s 1 normal forwards;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.git-info {
  display: flex;
  flex-direction: column;
}

.version, .time {
  color: #ccc;
}

.logout-btn {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="24" height="24" viewBox="0 0 24 24"%3E%3Cpath fill="red" d="m17 8l-1.4 1.4l1.6 1.6H9v2h8.2l-1.6 1.6L17 16l4-4l-4-4M5 5h7V3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h7v-2H5V5Z"%2F%3E%3C%2Fsvg%3E');
  height: 48px;
  width: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  background-color: transparent;
  border: none;
}

.manage-camera-btn {

  background-image: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="24" height="24" viewBox="0 0 24 24"%3E%3Cpath fill="white" d="M20 22h-6q-.425 0-.713-.288T13 21v-6q0-.425.288-.713T14 14h6q.425 0 .713.288T21 15v2l1.575-1.575q.125-.125.275-.063t.15.238v4.8q0 .175-.15.238t-.275-.063L21 19v2q0 .425-.288.713T20 22Zm-9 0h-.875q-.375 0-.65-.25t-.325-.625l-.3-2.325q-.325-.125-.613-.3t-.562-.375l-2.175.9q-.35.15-.7.038t-.55-.438L2.4 15.4q-.2-.325-.125-.7t.375-.6l1.875-1.425Q4.5 12.5 4.5 12.337v-.674q0-.163.025-.338L2.65 9.9q-.3-.225-.375-.6t.125-.7l1.85-3.225q.2-.325.55-.438t.7.038l2.175.9q.275-.2.575-.375t.6-.3l.3-2.325q.05-.375.325-.625t.65-.25h3.75q.375 0 .65.25t.325.625l.3 2.325q.325.125.613.3t.562.375l2.175-.9q.35-.15.7-.038t.55.438L21.6 8.6q.2.325.125.7t-.375.6l-1.875 1.425q.025.175.025.338V12h-3.95q0-1.45-1.025-2.475T12.05 8.5q-1.45 0-2.475 1.025T8.55 12q0 1.2.675 2.1T11 15.35V22Z"%2F%3E%3C%2Fsvg%3E');
  height: 48px;
  width: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  background-color: transparent;
  border: none;

}

.hidden {
  display: none;
}

.cameraImage {
  background: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  width: 130px;
  height: 130px;
  overflow: hidden;
  margin-bottom: 10px;
  cursor: pointer;
}
.cameraImage img.preview {
  height: 100%;
}

#movie-maker-modal {
  position: fixed;
  height: 350px;
  width: 600px;
  background-color: #1A1616;
  border-radius: 5px;
  border: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
}

#movie-maker-modal:focus {
  outline: none;
}

#movie-maker-modal::backdrop {
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-title {
  color: white;
  font-size: 1.625em;
}

.modal-subtitle {
  color: white;
  font-size: 1.125em;
  margin-bottom: 10px;
}

.modal-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-group button {
  background-color: white;
  height: 50px;
  border: 0;
  width: 80px;
  border-radius: 5px;
}

.modal-group input[type=datetime-local] {
  height: 50px;
  width: 350px;
  border-radius: 5px;
  background: rgba(219, 242, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.2);
  color: #ccc;
  padding: 20px;
  box-sizing: border-box;
}

.modal-footer button{
  padding: 15px 25px;

  background: #FFFFFF;
  border-radius: 5px;
  border: 0;
}

.modal-wrapper:first-child {
  margin-bottom: 20px;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-close {
  background: #d12020!important;
  color: white;
}

.modal-accept {
  background: #1c9f1c !important;
  color: white;
  position: relative;
}

.movie-maker-wrapper {
  position: fixed;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
}

.movie-maker-modal-backdrop {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.movie-maker-wrapper.hidden {
  display: none;
}

.event-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.clip-btn {
  margin-left: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M14.75 7.46L12 3.93l1.97-.39l2.74 3.53l-1.96.39m6.87-1.36l-.78-3.92l-3.93.78l2.74 3.54l1.97-.4M4 20v-8h16v1.09c.72.12 1.39.37 2 .72V10H2v10a2 2 0 0 0 2 2h9.81c-.35-.61-.59-1.28-.72-2H4m7.81-11.95L9.07 4.5l-1.97.41l2.75 3.53l1.96-.39M4.16 5.5l-.98.19a1.995 1.995 0 0 0-1.57 2.35L2 10l4.9-.97L4.16 5.5M20 18v-3h-2v3h-3v2h3v3h2v-3h3v-2h-3Z'/%3E%3C/svg%3E%0A");
  height: 40px;
  width: 40px;
  background-position: center;
  background-size: 100%;
  border: none;
  background-repeat: no-repeat;
  background-color: transparent;
}

.modal-close:disabled {
  opacity: 0.8;
}

.hide-download-text {
  visibility: hidden;
}

.download-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.checkmark {
  height: 120px;
  width: 120px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='120' height='120' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='green' stroke-miterlimit='10' stroke-width='32' d='M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192s192-86 192-192Z'/%3E%3Cpath fill='none' stroke='green' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M352 176L217.6 336L160 272'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: repeat;
  margin-bottom: 20px;
}

.checkmark-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}

.checkmark-box h2 {
  font-size: 1.625em;
}

.modal-body-checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 1.625em);
}

.showIFrameMode {
  color: white;
}

.showHolywoodMessage {
    color: white;
  }

.showEventTypesMessage {
    color: white;
  }
</style>
