/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:d553f1ab-c1bd-4b35-8d06-c57515e4a244",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_5FnstvJXD",
    "aws_user_pools_web_client_id": "6kgdrqurashuqtgbfiq4ra5b29",
    "oauth": {}
};


export default awsmobile;
