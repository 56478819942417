import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


// * as AmplifyModules
import Amplify from 'aws-amplify';
// import { AmplifyPlugin } from 'aws-amplify-vue';
import { Auth } from 'aws-amplify';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

createApp(App).use(store).use(router).use(Auth).mount('#app')
