function createMP4Clip(camera, name, start, end) {

    if (!name.endsWith('.mp4')) {
        name = name + '.mp4'
    }

    let message = {
        topic: 'cmd/devices/hilary-dev/data-channel-relay',
        destination: 'mmctrl',
        action: {
            command: "make-v",
            data: {
                source: camera,
                ts: {
                    s: start,
                    e: end
                },
                output: name
            }
        }
    }

    return message

}

module.exports = { createMP4Clip }